/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-progress-spinner circle, .mat-spinner circle {
    stroke: #cf8757db !important;
}
.fancy-box-3 img{
    border-radius: 10px 10px 0px 0px;
}
